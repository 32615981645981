import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import { AppLanguage } from './types';
import { APP_LANGUAGE_KEY } from 'shared/utils/constants';
import { API_HOST } from 'app/config/envVariables';
import { DEFAULT_APP_LANGUAGE } from './constants';

const storedLanguage = localStorage.getItem(APP_LANGUAGE_KEY) as AppLanguage;

if (!storedLanguage) {
  localStorage.setItem(APP_LANGUAGE_KEY, DEFAULT_APP_LANGUAGE);
}

const initI18n = async (): Promise<void> => {
  await i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
      backend: {
        loadPath: async (languages: AppLanguage[], namespaces: string[]) => {
          const [language] = languages;
          const [namespace] = namespaces;

          if (Object.values(AppLanguage).includes(language)) {
            return `${API_HOST}/locales/${language}/${namespace}.json`;
          }
        },
      },
      interpolation: {
        escapeValue: false,
      },
      lng: storedLanguage || DEFAULT_APP_LANGUAGE,
      ns: 'booking',
    });
};

initI18n().catch((error) => {
  console.error('Error initializing i18n:', error);
});

export default i18n;
